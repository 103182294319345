import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './css/FAQ.css';

const FAQ = () => {
    const [activeKey, setActiveKey] = useState(null);
    const [visibleCount, setVisibleCount] = useState(5);

    const toggleFAQ = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    const showMoreFAQs = () => {
        setVisibleCount(visibleCount + faqs.length);
    };

    const faqs = [
        {
            key: '0',
            question: 'Which drones are compatible with Drone AI?',
            answer: 'Currently, Drone AI primarily supports the DJI Tello drone.  Support for additional drones is planned for future releases. Custom integrations are also available; please contact us for more information.'
        },
        {
            key: '1',
            question: 'Is Insight AI and Drone AI free to use?',
            answer: 'Core functionality of Insight AI and Drone AI is free.  However, some advanced features and priority support are included in a Pro subscription.'
        },
        {
            key: '2',
            question: 'Can your software be integrated with custom drones and flight controllers?',
            answer: 'Yes, custom drone integrations are available for both Insight AI and Drone AI. Contact us to discuss your specific needs and API integration requirements.'
        },
        {
            key: '3',
            question: 'How do I get started with Tensor Pilot?',
            answer: 'Download the application for macOS from our website or the Windows application from the Microsoft Store. Both the applications are vetted and signed by Apple and Msft services for any malware or viruses.'
        },
        {
            key: '4',
            question: 'What features are included in the Pro subscription for Insight AI?',
            answer: 'The Insight AI Pro subscription unlocks advanced AI features like enhanced object detection and tracking parameters, more precise pose estimation, and expanded face mesh analysis options. It also includes priority support and early access to new features.'
        },
        {
            key: '5',
            question: 'What features are included in the Pro subscription for Drone AI?',
            answer: 'The Drone AI Pro subscription could include features such as advanced flight planning capabilities, automated flight modes (e.g., cinematic shots), and prioritized access to new flight features and drone support.'
        },
        {
            key: '6',
            question: 'What kind of support do you offer?',
            answer: 'We offer email, chat, and community forum support. Pro subscribers receive priority support with faster response times.'
        },
        {
            key: '7',
            question: 'What are the system requirements for Tensor Pilot?',
            answer: 'Tensor Pilot requires a Windows or macOS operating system, a modern multi-core processor, at least 8GB of RAM for running Vision and Drone AI, and a stable internet connection for Insight AI.'
        },
        {
            key: '8',
            question: 'Is the software compatible with mobile devices?',
            answer: 'Currently, Tensor Pilot is primarily designed for desktop and laptop computers. Mobile compatibility is planned for future releases.'
        },
        {
            key: '9',
            question: 'What AI models does Insight AI support?',
            answer: 'Insight AI currently supports `gemini-1.5-flash`, `gemini-1.5-flash-8b`, and `gemini-1.5-pro`.  Support for additional models may be added in the future.'
        },
        {
            key: '10',
            question: 'What types of video analysis can Insight AI perform?',
            answer: 'Insight AI performs object detection, face detection, hand gesture recognition, face mesh analysis, pose estimation, and object tracking.  The specific analysis options available depend on the selected AI model and configuration. You can also query your videos with Gemini.'
        },
        {
            key: '11',
            question: 'How does the conversational AI in Insight AI work?',
            answer: 'The conversational AI in Insight AI allows you to analyze video content through natural language prompts. You can ask questions about the video, and the AI will provide textual responses based on its analysis.'
        },
        {
            key: '13',
            question: 'How are chats stored?',
            answer: 'Chats are stored locally on your computer, within the workspaces you create.  The exact location of these files can be accessed by clicking "Show my data" in the "My Data" section of the application.  This allows you to manage and, if needed, delete your data directly.  Note that all prompts and responses are also processed through the Gemini API and are subject to Google\'s data usage policies.'
        },        
        {
            key: '14',
            question: 'Do I need to download separate applications for Drone AI, Insight AI, and Vision AI?',
            answer: 'No, all functionalities (Drone AI, Insight AI, Vision AI etc) are integrated within a single DroneEliApp application.'
        }
    ];
    

    return (
        <div className="faq-container">
            <h4 className='text-center mb-4'>
                FAQ
            </h4>
            <Accordion activeKey={activeKey}>
                {faqs.slice(0, visibleCount).map(faq => (
                    <Card key={faq.key} className="faq-item">
                        <Card.Header onClick={() => toggleFAQ(faq.key)} className="faq-header">
                            <Accordion.Toggle as={Button} variant="" className="faq-button">
                                <div className="faq-content">
                                    <p className="faq-question">{faq.question}</p>
                                    <FontAwesomeIcon 
                                        icon={activeKey === faq.key ? faChevronUp : faChevronDown} 
                                        className="faq-icon" 
                                    />
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={faq.key}>
                            <Card.Body className="faq-answer">{faq.answer}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
            {visibleCount < faqs.length && (
                <div className="text-center mt-4">
                    <Button onClick={showMoreFAQs} variant="primary">Show More</Button>
                </div>
            )}
        </div>
    );
};

export default FAQ;
