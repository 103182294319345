import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Youtubeplayer from '../../sharedcomponents/youtubeplayer/youtubeplayer';
import './css/YoutubeVideoSection.css';

const YoutubeVideoSection = ({ heading, url }) => {
    return (
        <div className="youtube-box mb-5">
            
            <Col className='mb-4 text-center mt-4'>
                <p className='section-subheading text-muted'>{heading}</p>
            </Col>
            
            {/* Center the video in the row */}
            <Col>
            <Row className="justify-content-md-center">
                <Col xs={12} md={10} lg={10}>
                    <div className="video-container">
                        <Youtubeplayer 
                            url={url} 
                            width="100%"
                            height="100%"
                        />
                    </div>
                </Col>
            </Row>
            </Col>
        
        </div>
    );
}

export default YoutubeVideoSection;
