import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faLock, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './css/InsightAISection.css';

import VideoAnalaysis from '../assets/video-analysis.webp';
import GoogleGemini from '../assets/google-gemini.webp'
import SecureLocation from '../assets/secure-location.webp'
import PersonalWorkspaces from '../assets/personal-workspaces.webp'


const features = [
    {
        id: 'video-analysis',
        title: "Intelligent Video Analysis",
        description: ["Analyze videos with Gemini without tedious manual review"],
        icon: faVideo,
        color: '#FFD700',
        image: PersonalWorkspaces
    },
    {
        id: 'powerful-ai-engine',
        title: "Powered by Google's Gemini",
        description: ["Harness the power of cutting-edge AI models"],
        icon: faGoogle,
        color: '#FF5722',
        image: GoogleGemini
    },
    {
        id: 'secure-conversations',
        title: "Secure Local Conversations",
        description: ["All chats are stored securely and privately on your device"],
        icon: faLock,
        color: '#4CAF50',
        image: SecureLocation
    },
    {
        id: 'personalized-workspaces',
        title: "Personalized Workspaces",
        description: [
            "Add folders and query files, media, and images directly within InsightAI.",
        ],
        icon: faUserCog,
        color: '#2196F3',
        image: VideoAnalaysis
    }
];

const InsightAISection = ({ handleDownloadRef, handleShowDemo }) => {
    // State for modal
    const [selectedImage, setSelectedImage] = useState(null);

    // Initialize AOS
    useEffect(() => {
        AOS.init({ duration: 1200, once: true });
    }, []);

    // Handler to close the modal
    const handleClose = () => setSelectedImage(null);

    return (
        <div className="insight-ai-section">
            <div className="glassmorphic-background"></div>
            <div className="">
                <Row className='ai-feature-description'>
                    <Col className="mb-4">
                        <center>
                            <h1 className="gradient-text">Insight AI</h1>
                        </center>
                        <p className="text-center mb-5">Your Personal AI Assistant</p>
                        <Row>
                            {features.map((feature, index) => (
                                <Col lg={6} md={6} sm={12} key={feature.id} className='mb-4'>
                                    <div className="insightai-feature-item" data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}>
                                        <div className="feature-icon-container">
                                            <FontAwesomeIcon icon={feature.icon} className={`insightai-feature-icon ${feature.color}`} />
                                        </div>
                                        <p className='insightai-feature-title'>{feature.title}</p>
                                        <img
                                            src={feature.image}
                                            alt={feature.title}
                                            className="feature-image"
                                            onClick={() => setSelectedImage(feature.image)}  // open modal on click
                                        />
                                        <p className='text-muted mt-2'>{feature.description[0]}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>

            {/* Modal for Magnified Image */}
            <Modal show={!!selectedImage} onHide={handleClose} centered size='xl'>
                <Modal.Body>
                    <img 
                        src={selectedImage} 
                        alt="Magnified" 
                        className="img-fluid" 
                        style={{ borderRadius: '8px' }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default InsightAISection;
