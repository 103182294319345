import React from 'react';
import { Container, Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faGamepad, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './css/DroneControlOptions.css';

const DroneControlOptions = () => {

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip">
            {text}
        </Tooltip>
    );

    return (
        <Container className='mt-5'>
            <h3 className="section-subheading text-center text-muted mb-1 mt-4 mb-3">Hybrid Input</h3>
            
            <Row className='justify-content-center'>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                    <Card border="primary" className="feature-card h-100 text-center shadow-sm d-flex flex-column align-items-center justify-content-center">
                        <Card.Body className="d-flex flex-column align-items-center">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip('Keyboard Controls')}
                            >
                                <FontAwesomeIcon 
                                    icon={faKeyboard} 
                                    size="2x" 
                                    className="feature-icon mb-3" 
                                    // onClick handler removed as modal is no longer needed
                                />
                            </OverlayTrigger>
                            <Card.Title className='drone-control-title'>Keyboard</Card.Title>
                            <Card.Text className='text-sm'>
                                <p className='text-muted'>Precise & Quick Commands</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                    <Card border="primary" className="feature-card h-100 text-center shadow-sm d-flex flex-column align-items-center justify-content-center">
                        <Card.Body className="d-flex flex-column align-items-center">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip('Gamepad Controls')}
                            >
                                <FontAwesomeIcon 
                                    icon={faGamepad} 
                                    size="2x" 
                                    className="feature-icon mb-3"
                                    // onClick handler removed as modal is no longer needed
                                />
                            </OverlayTrigger>
                            <Card.Title className='drone-control-title'>Gamepad</Card.Title>
                            <Card.Text className='text-sm'>
                            <p className='text-muted'>Ergonomic & Intuitive</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    
    );
};

export default DroneControlOptions;
