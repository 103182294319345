import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';
import NavBarMain from '../../sharedcomponents/navbar/Navbar';
import { Button } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeadset,  faUniversalAccess, faEye, faLightbulb, faVideo, faStar, faRocket, faBrain, faCogs, faToolbox, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DroneAISection from './DroneAISection/DroneAISection';
import UserTypesSection from './UserTypesSection';
import CallToActionSection from './CallToActionSection';
import PublicDownload  from './PublicDownload';
import FeaturesList from './FeatureList';
import FAQ from './FAQ';
import Helmet from 'react-helmet';
import Logo from '../../assets/Logo.png';
import { TbSparkles } from 'react-icons/tb';

import './css/Home.css';
import InsightAISection from './InsightAISection/InsightAISection';

library.add(faApple, faWindows, faHeadset, faUniversalAccess, faLightbulb, faVideo, faStar, faRocket, faBrain, faCogs, faToolbox, faDownload);

function TypingEffect() {
    const [i, setI] = useState(0);
    const txt = 'Next-Gen Robotics with Personal AI';
    const speed = 50;

    useEffect(() => {
        const typeWriter = () => {
            if (i < txt.length) {
                setI((prevI) => prevI + 1);
            }
        };

        const interval = setInterval(typeWriter, speed);

        return () => clearInterval(interval);
    }, [i]);

    const isTypingComplete = i !== txt.length;

    return (
        <div className="gradient-text">
            {txt.slice(0, i)}
            {isTypingComplete && <span className="cursor">|</span>}
        </div>
    );
}

const Home = () => {
    const demoRef = useRef(null);
    const downloadRef = useRef(null);
    const droneAIRef =  useRef(null);
    const insightAIRef =  useRef(null);

    const [visibleListItems, setVisibleListItems] = useState(0); // To track the visibility of list items

    const scrollToDroneAI = () => {
        droneAIRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToInsightAI = () => {
        insightAIRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        // Gradually make each list item visible with a delay
        const timer = setInterval(() => {
            setVisibleListItems((prevVisibleItems) => {
                if (prevVisibleItems < 5) {
                    return prevVisibleItems + 1; // Increment the number of visible items
                } else {
                    clearInterval(timer);
                    return prevVisibleItems;
                }
            });
        }, 300); // Adjust the delay between items here (300ms)

        return () => clearInterval(timer);
    }, []);
    
    const handleShowDemo = () => {
        demoRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDownloadRef = () => {
        downloadRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const location = useLocation();
    useEffect(() => {
        // Check if location.hash exists and matches an element
        if (location.hash) {
            const targetId = location.hash.replace("#", ""); // Remove '#' from hash
            const element = document.getElementById(targetId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]); // Run every time location changes


    const [animateButtons, setAnimateButtons] = useState(false);
    useEffect(() => {
        if (visibleListItems === 5) {
            setAnimateButtons(true);
        }
    }, [visibleListItems]);

    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>Home - Tensor Pilot</title>

                {/* Meta Description for SEO */}
                <meta name="description" content="Explore Tensor Pilot AI Drone Software for cutting-edge solutions in autonomous flying, real-time object detection, and advanced data analytics. Perfect for drones in enterprise, education, and retail sectors." />

                {/* SEO Keywords */}
                <meta name="keywords" content="Tensor Pilot, AI Drone Software, Insight AI for footage or video analysis with Google's latest Gemini models, Autonomous Flying, Real-Time Object Detection, Drone Analytics, Enterprise Drone Solutions, AI Drone Technology, Drone Education, Drone Retail, Human Pose Detection Drones, Object Tracking Drones" />

                {/* Open Graph Meta Tags for Social Media */}
                <meta property="og:title" content="Home - Tensor Pilot" />
                <meta property="og:description" content="Discover Tensor Pilot AI Drone Software with advanced features for autonomous flying, surveillance, and real-time analytics. Ideal for enterprise, education, and retail drones." />
                <meta property="og:url" content="https://www.aidronesoftware.com/" />
                <meta property="og:site_name" content="Tensor Pilot AI Drone Software" />
                <meta property="og:image" content={Logo} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home - Tensor Pilot an AI Drone Software" />
                <meta name="twitter:description" content="Tensor Pilot AI Drone Software provides advanced AI solutions for autonomous flying, surveillance, and data analytics, designed for enterprise, education, and retail drones." />
                <meta name="twitter:image" content={Logo} />

                {/* Additional Meta Tags for Mobile Responsiveness and Compatibility */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            
            <script src="https://js.storylane.io/js/v1/storylane.js"></script>

            <NavBarMain 
                scrollToDroneAI={scrollToDroneAI}
                scrollToInsightAI={scrollToInsightAI}
            />
            <Container className="col-lg-8 col-md-10 col-12  home-container">

                <Row>
                    <Col lg={12} md={12} sm={12}>
                    <center>
                        <p className="gradient-text welcome-title">
                            <TypingEffect />
                        </p>
                    </center>

                        <div className="home-subheader pl-1">
                            <center>
                            <FeaturesList 
                            handleDownloadRef={handleDownloadRef}
                            scrollToDroneAI={scrollToDroneAI}
                            scrollToInsightAI={scrollToInsightAI}
                            />
                            </center>
                        </div>
                            

                        <div className="custom-buttons-container my-4">
                            <center>
                            <Button 
                                variant="outline-light fade-in delay-6"
                                className={`btn btn-lg border-1 full-width custom-download-button mx-2 my-2 ${animateButtons ? 'fade-in delay-6' : 'hidden'}`} 
                                onClick={handleDownloadRef}
                            >
                                <b>
                                <FontAwesomeIcon icon={['fab', 'windows']} />  <FontAwesomeIcon icon={['fab', 'apple']} /> Free Download 
                                </b>
                            </Button>
                            
                            <Button 
                                className={`btn btn-lg center mx-2 my-2 hide-on-mobile full-width ${animateButtons ? 'fade-in delay-7' : 'hidden'}`} 
                                variant='outline-primary'
                                aria-label="Download for Apple"
                                onClick={handleShowDemo}
                            >
                                <b>Try demo <TbSparkles className="home-sparkle-icon" /></b> 
                            </Button>
                            </center>
                        </div>
                    </Col>

                        
                    <Col lg={12} md={12} sm={12} className="my-3 text-center" ref={demoRef}>
                        <center>
                            <div className="iframe-storylane-box">
                                <iframe
                                    src="https://app.storylane.io/demo/abhyadrrxvil"
                                    name="sl-embed"
                                    allow="fullscreen"
                                    className="sl-demo iframe-storylane"
                                    style={{ border: 'none', overflow: 'hidden' }} 
                                />

                            </div>
                        </center>
                    </Col>
                </Row>

                
                <Row>
                    <div>
                        <div id="download-software-section" ref={downloadRef}>
                            <PublicDownload />
                        </div>        

                        <div id="drone-ai-section" ref={droneAIRef}>
                            <DroneAISection 
                                scrollToInsightAI={scrollToInsightAI}
                            />
                        </div>
                        
                        <UserTypesSection />

                        <div
                            className='mt-5' 
                            id='insight-ai-section' 
                            ref={insightAIRef}>
                            <InsightAISection 
                                handleDownloadRef={handleDownloadRef} 
                                handleShowDemo= {handleShowDemo}
                            />
                        </div>                       

                    </div>
                </Row>
                
                
                <Row className="justify-content-center">
                    <div className='call-to-action-section'>
                        <PublicDownload />
                    
                        <CallToActionSection 
                            handleDownloadRef={handleDownloadRef}
                        />
                        <FAQ />
                    </div>
                </Row>        
            </Container>
        </>
    )
}

export default Home;
