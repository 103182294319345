// src/components/DownloadSection/PublicDownload.js

import React, { useState, useRef } from 'react';
import {
    Button,
    Spinner,
    Modal,
    Row,
    Col,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faApple,
    faWindows
} from '@fortawesome/free-brands-svg-icons';
import {
    faDownload,
    faCheckCircle,
    faShieldAlt,
    faList
} from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './css/PublicDownload.css'; 

// Replace with your actual reCAPTCHA Site Key
const RECAPTCHA_SITE_KEY = '6LcjnS4bAAAAAI3HKCTce9dAnOucKEalagR65dtr'; 

const PublicDownload = () => {
    const [isDownloadingIntel, setIsDownloadingIntel] = useState(false);
    const [isDownloadingArm, setIsDownloadingArm] = useState(false);
    const [isLoggingWindows, setIsLoggingWindows] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertVariant, setAlertVariant] = useState('success');
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [downloadType, setDownloadType] = useState(null); 
    const recaptchaRef = useRef(null);

    const [showPrivacyFeatures, setShowPrivacyFeatures] = useState(false);

    const initiateDownload = (type) => {
        setDownloadType(type);
        setShowCaptcha(true);
    };

    const onCaptchaSuccess = async (token) => {
        setShowCaptcha(false);
        if (!downloadType) {
            setAlertMessage('Invalid download type.');
            setAlertVariant('danger');
            setShowAlertModal(true);
            return;
        }

        try {
            if (downloadType === 'MacIntel') {
                setIsDownloadingIntel(true);
                await handleDownloadMac('intel', token);
            } else if (downloadType === 'MacARM') {
                setIsDownloadingArm(true);
                await handleDownloadMac('arm', token);
            } else if (downloadType === 'Windows') {
                setIsLoggingWindows(true);
                await handleDownloadWindows(token);
            }
        } catch (error) {
            console.error('Download error:', error);
            setAlertMessage('Failed to start the download. Please try again later.');
            setAlertVariant('danger');
            setShowAlertModal(true);
        } finally {
            setIsDownloadingIntel(false); 
            setIsDownloadingArm(false); 
            setIsLoggingWindows(false);
            setDownloadType(null);
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        }
    };

    const onCaptchaFailure = () => {
        setShowCaptcha(false);
        setDownloadType(null);
        setAlertMessage('Captcha verification failed. Please try again.');
        setAlertVariant('danger');
        setShowAlertModal(true);
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    const handleDownloadMac = async (arch, captchaToken) => {
        const response = await axios.get('/api/public/download', {
            headers: {
                Platform: 'Mac',
                Arch: arch,
                'Captcha-Token': captchaToken,
            },
        });

        const { url } = response.data;
        const a = document.createElement('a');
        a.href = url;
        a.download = ''; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setAlertMessage('Download started successfully.');
        setAlertVariant('success');
        setShowAlertModal(true);
    };

    const handleDownloadWindows = async (captchaToken) => {
        try {
            await axios.post(
                '/api/public/logDownload',
                { platform: 'Windows' },
                {
                    headers: {
                        'Captcha-Token': captchaToken,
                    },
                }
            );

            const newWindow = window.open(
                'https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS',
                '_blank'
            );

            if (newWindow === null) {
                setAlertMessage(
                    <>
                        <p>
                            Pop-up was blocked! Please allow pop-ups for this site or click the link
                            below to navigate to Windows Store.
                        </p>
                        <p>
                            <a
                                href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Microsoft Store
                            </a>
                        </p>
                    </>
                );
                setAlertVariant('danger');
            } else {
                setAlertMessage('Redirecting to Windows Store...');
                setAlertVariant('success');
            }
            setShowAlertModal(true);
        } catch (error) {
            console.error('Error logging the Windows download event:', error);
            setAlertMessage(
                <>
                    <p>
                        Something went wrong, you will still be redirected. If the redirection didn't
                        work, please click the link below:
                    </p>
                    <p>
                        <a
                            href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Go to Microsoft Store
                        </a>
                    </p>
                </>
            );
            setAlertVariant('warning');
            setShowAlertModal(true);

            const newWindowFallback = window.open(
                'https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS',
                '_blank'
            );

            if (newWindowFallback === null) {
                setAlertMessage(
                    <>
                        <p>
                            Pop-up was blocked! Please allow pop-ups for this site and click the link below
                            to proceed.
                        </p>
                        <p>
                            <a
                                href="https://apps.microsoft.com/store/detail/9P7MRSZSCWNN?cid=DevShareMCLPCS"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Go to Microsoft Store
                            </a>
                        </p>
                    </>
                );
                setAlertVariant('danger');
                setShowAlertModal(true);
            }
        } 
    };


    return (
        <div className="download-section my-4">
            {/* Header */}
            <Row className="text-center mt-3">
                <Col>
                    <p className="download-title gradient-text">Download Tensor Pilot</p>
                    <p className=" subtitle-text">Your Personal AI</p>
                </Col>
            </Row>

            {/* Privacy/Features Button */}
            <Row className="justify-content-center mb-4">
                <Col xs={12} md={8} lg={6} className="text-center">
                    <Button
                        size='sm'
                        variant="outline-info"
                        onClick={() => setShowPrivacyFeatures(true)}
                    >
                        <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                        Privacy & Data
                    </Button>
                </Col>
            </Row>

            <div className="custom-flow">
                <div className="flow-item">
                    <FontAwesomeIcon
                        icon={faApple}
                        size="3x"
                        className="mb-3 apple-icon"
                    />
                    <div className="trusted-badges mb-2">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-apple">Verified by Apple</Tooltip>}
                        >
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color="green"
                                size="sm"
                                aria-label="Verified by Apple"
                            />
                        </OverlayTrigger>
                    </div>
                    <p className='public-download-name'>
                        Mac OS (Intel)
                    </p>

                    <Button
                        variant="outline-primary"
                        className="mt-auto btn-download"
                        onClick={() => initiateDownload('MacIntel')}
                        disabled={isDownloadingIntel || isLoggingWindows} 
                        aria-label="Download Mac OS (Intel)"
                    >
                        {isDownloadingIntel ? ( 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                Downloading...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faDownload} /> Free Download
                            </>
                        )}
                    </Button>
                </div>

                <div className="flow-item">
                    <FontAwesomeIcon
                        icon={faApple}
                        size="3x"
                        className="mb-3 apple-icon"
                    />
                    <div className="trusted-badges mb-2">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-apple">Verified by Apple</Tooltip>}
                        >
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color="green"
                                size="sm"
                                aria-label="Verified by Apple"
                            />
                        </OverlayTrigger>
                    </div>
                    <p className='public-download-name'>Mac OS (ARM)</p>

                    <Button
                        variant="outline-primary"
                        className="mt-auto btn-download"
                        onClick={() => initiateDownload('MacARM')}
                        disabled={isDownloadingArm || isLoggingWindows}
                        aria-label="Download Mac OS (ARM)"
                    >
                        {isDownloadingArm ? ( 
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                Downloading...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faDownload} /> Free Download
                            </>
                        )}
                    </Button>
                </div>

                <div className="flow-item">
                    <FontAwesomeIcon
                        icon={faWindows}
                        size="3x"
                        className="mb-3 windows-icon"
                    />
                    <div className="trusted-badges mb-2">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-windows">Verified by Microsoft</Tooltip>}
                        >
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color="green"
                                size="sm"
                                aria-label="Verified by Microsoft"
                            />
                        </OverlayTrigger>
                    </div>
                    <p className='public-download-name'>Windows</p>

                    <Button
                        variant="outline-primary"
                        className="mt-auto btn-download"
                        onClick={() => initiateDownload('Windows')}
                        disabled={isDownloadingIntel || isDownloadingArm || isLoggingWindows} 
                        aria-label="Download Windows"
                    >
                        {isLoggingWindows ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                Redirecting...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faDownload} /> Free Download
                            </>
                        )}
                    </Button>
                </div>
            </div>

            <Modal show={showPrivacyFeatures} onHide={() => setShowPrivacyFeatures(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Privacy & Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
                        Privacy
                    </h5>
                    <p>
                        We prioritize your privacy and ensure that all your data is handled securely.
                        Here are some key aspects:
                    </p>
                    <ul>
                        <li>Your data is stored locally and not backed up to external servers.</li>
                        <li>We do not store or access your Gemini API keys.</li>
                        <li>
                            All data processing is conducted through the Gemini API in compliance with
                            Google's data usage policies.
                        </li>
                        <li>
                            You have full control over your data, with options to manage and delete it as
                            needed.
                        </li>
                    </ul>
                    <h5 className="mt-4">
                        <FontAwesomeIcon icon={faList} className="mr-2" />
                        Features
                    </h5>
                    <ul>
                        <li>Free to download and install.</li>
                        <li>Basic features available at no cost.</li>
                        <li>Advanced features available through in-app purchases or subscriptions.</li>
                        <li>Seamless integration with the Gemini API for enhanced functionality.</li>
                        <li>Regular updates with new features and improvements.</li>
                    </ul>
                    <p>
                        For more detailed information, please refer to our{' '}
                        <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="a-onclick"
                        >
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a
                            href="/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="a-onclick"
                        >
                            Terms & Conditions
                        </a>.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPrivacyFeatures(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCaptcha} onHide={() => setShowCaptcha(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Please complete the CAPTCHA to proceed with the download. By solving the
                        captcha you agree to our{' '}
                        <a
                            href="/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms & Conditions
                        </a>{' '}
                        and{' '}
                        <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                    <ReCAPTCHA
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={onCaptchaSuccess}
                        onErrored={onCaptchaFailure}
                        onExpired={onCaptchaFailure}
                        ref={recaptchaRef}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCaptcha(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showAlertModal}
                onHide={() => {
                    setShowAlertModal(false);
                    setAlertMessage(null);
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>{alertMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowAlertModal(false);
                            setAlertMessage(null);
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PublicDownload;
