import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Logo from '../../assets/Logo.png';

import './Navbar.css'; 

const NavBarMain = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch (error) {
      console.error("Failed to log out", error);
    }
  }

  // Function to handle navigation based on props
  const handleNavigation = (link, scrollToFunction) => {
    if (scrollToFunction) {
      console.log("scroll called")
      scrollToFunction(); // Call the scroll function passed as a prop
    } else {
      console.log("schistory.push called")
      history.push(link); // Use normal navigation
    }
  };

  return (
    <Navbar 
      className="px-4"
      variant={props.variant} 
      expand="lg"
    >
      <Navbar.Brand as={Link} to="/" className="navbar-brand">
          <img 
            className="navbar-logo"
            src={Logo} 
            alt="Drone AI - Tensor Pilot Logo"
          />
          <p className="navbar-brand-text">Tensor Pilot</p>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link 
            as={Link} 
            to="/" 
            className="nav-link" 
            aria-label="Home"
          >
            <b>Home</b>
          </Nav.Link>

          <NavDropdown 
            title={<b>Products</b>} 
            id="basic-nav-dropdown"
            show={showDropdown} 
            onMouseEnter={() => setShowDropdown(true)} 
            onMouseLeave={() => setShowDropdown(false)}
          >
            <NavDropdown.Item 
              as="div"
              aria-label="Insight AI" 
              onClick={() => handleNavigation('/#insight-ai-section', props.scrollToInsightAI)} // Pass scroll function
            >
              Insight AI
            </NavDropdown.Item>

            <NavDropdown.Item 
              as="div"
              className="navDropdown-item"
              aria-label="Drone AI" 
              onClick={() => handleNavigation('/#drone-ai-section', props.scrollToDroneAI)} // Pass scroll function
            >
              Drone AI
            </NavDropdown.Item>

            {/* <NavDropdown.Item 
              as="div"
              aria-label="Vision AI" 
              onClick={() => handleNavigation('/#vision-ai-section', props.scrollToVisionAI)} // Pass scroll function
            >
              Vision AI
            </NavDropdown.Item> */}
          </NavDropdown>


          <Nav.Link 
            as={Link} 
            aria-label="Pricing" 
            to="/sales" 
            className="nav-link"
          >
            <b>Pricing</b>
          </Nav.Link>
          <Nav.Link 
            as={Link} 
            to="/blog" 
            aria-label="Blog" 
            className="nav-link"
          >
            <b>Blog</b>
          </Nav.Link>

          <Nav.Link 
            as={Link} 
            to="/#download-software-section" 
            aria-label="Blog" 
            className="nav-link"
          >
            <b>Download</b>
          </Nav.Link>

          <Nav.Link 
            as={Link} 
            to="/contactus" 
            aria-label="Support" 
            className="nav-link"
          >
            <b>Support</b>
          </Nav.Link> 
          
          {currentUser && currentUser.email ? (
            <>
              <Nav.Link 
                as={Link} 
                to="/profile" 
                aria-label="Profile" 
                className="nav-link"
              >
                <b>Profile</b>
              </Nav.Link>
              <Nav.Link 
                id="logging" 
                onClick={handleLogout} 
                className="nav-link"
              >
                <button 
                  type="button" 
                  aria-label="Sign out of your account" 
                  className="btn btn-outline-primary "
                >
                  <b>Sign Out</b>
                </button>
              </Nav.Link>
            </>
          ) : (
            <Nav.Link 
              as={Link} 
              id="logging" 
              to="/login" 
              className="nav-link"
            >
                <button 
                  type="button" 
                  aria-label="Sign in to your account" 
                  className="btn btn-outline-primary "
                >
                  <b>Sign In</b> 
                </button>
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarMain;