import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversalAccess, 
    faLightbulb, 
    faSmileBeam, 
    faHandSparkles,
    faPlug,
    faMicrochip,
    faCogs,
    faHandshake
 } from '@fortawesome/free-solid-svg-icons';
import { TbDrone } from 'react-icons/tb';
import { TbSparkles } from 'react-icons/tb'; // Importing from react-icons

import ObjectDetection from '../assets/ObjectDetection.webp';
import HandLandMarkDetection from '../assets/HandLandMarkDetection.webp';
import HumanPoseDetection from '../assets/HumanPoseDetection.webp';
import FaceMesh from '../assets/FaceMesh.webp';
import DroneControlOptions from './DroneControlOptions';
import YoutubeVideoSection from '../YoutubeVideoSection';
import CinematographyFeatures from './CinematographyFeatures';


import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';  // Import AOS styles

import './css/DroneAISection.css';

const features = [
    {
        id: 'object-detection',
        title: "Object Detection",
        description: [
            "Real-time object identification and categorization."
        ],
        icon: faLightbulb,
        color: '#FFD700', // Gold
        gif: ObjectDetection
    },
    {
        id: 'pose-detection',
        title: "Pose Detection",
        description: [
            "Provides precise body pose landmarks for rescue operations or sports and training.",
        ],
        icon: faUniversalAccess,
        color: '#4CAF50', // Green
        gif: HumanPoseDetection
    },
    {
        id: 'face-landmark-detection',
        title: "Face Landmark Detection",
        description: [
            "Detects human faces and key facial landmarks."
        ],
        icon: faSmileBeam,
        color: '#2196F3', // Blue
        gif: FaceMesh
    },
    {
        id: 'hand-gesture-recognition',
        title: "Hand Gesture Recognition",
        description: [
            "Interprets specific hand gestures in real-time."
        ],
        icon: faHandSparkles,
        color: '#FF5722', // Deep Orange
        gif: HandLandMarkDetection
    }
];

const AIFeaturesDisplay = ({scrollToInsightAI}) => {

    // Initialize AOS
    useEffect(() => {
        AOS.init({ duration: 1200, once: true });
    }, []);

    return (
        <div className="mt-5 ai-feature">
            {/* Glassmorphic background */}
            <div className="glassmorphic-background"></div>
            <div className="feature-container">
                <Row className='ai-feature-description'>
                    <Col className="mb-4">
                        <center>
                            <h1 className="gradient-text">
                                Drone AI
                            </h1>
                        </center>
                        <div className="custom-flow">
                            <div className="flow-item">
                                {/* <FontAwesomeIcon icon={faPlug} className="flow-icon" /> */}
                                <TbDrone className="flow-icon" /> 
                                <div className="flow-content">
                                    Effortlessly connect with 
                                    <a href="https://www.ryzerobotics.com/tello" target="_blank" rel="noopener noreferrer" className="flow-link">
                                        <strong> DJI Tello drones </strong>
                                    </a>
                                    ready out of the box. For other custom drone integration please
                                    <a href="/contactus" target="_blank" rel="noopener noreferrer" className="flow-link">
                                        <strong> Contact Us</strong>
                                    </a>
                                </div>
                            </div>
                            <div className="flow-item">
                                <FontAwesomeIcon icon={faMicrochip} className="flow-icon" />
                                <div className="flow-content">
                                    Tesnsor Pilot runs Google's 
                                    <a href="https://ai.google.dev/edge/mediapipe/solutions/guide" target="_blank" rel="noopener noreferrer" className="flow-link">
                                        <strong>MediaPipe </strong>
                                    </a> advanced ML algorithms locally on your device for optimal performance and efficiency.
                                </div>
                            </div>
                            <div className="flow-item">
                                <FontAwesomeIcon icon={faCogs} className="flow-icon" />
                                <div className="flow-content">
                                    Integrate with any drone or robot effortlessly —
                                    {/* <a href="/custom-integrations" target="_blank" rel="noopener noreferrer" className="flow-link"> */}
                                        our software adapts to your needs.
                                    {/* </a> */}
                                    <a href="/contactus" target="_blank" rel="noopener noreferrer" className="flow-link">
                                        <strong> Contact Us</strong>
                                    </a>
                                </div>
                            </div>
                            
                            <div className="flow-item">
                                {/* <FontAwesomeIcon icon={faWandSparkles} className="flow-icon" /> */}
                                <TbSparkles className="flow-icon" />
                                <div className="flow-content">
                                    Process your video footage with 
                                    <a  onClick={() => scrollToInsightAI()} 
                                        rel="noopener noreferrer" 
                                        className="flow-link">
                                        InsightAI
                                    </a> using
                                    <a href="https://ai.google.dev/gemini-api/docs/vision" target="_blank" rel="noopener noreferrer" className="flow-link">
                                        Google's Gemini
                                    </a> within the application.
                                </div>
                            </div>
                        </div>
                    </Col>

                    
                    <div>
                    <p className="section-subheading text-center text-muted">Advance Drone Operations</p>
                    <center><p className='section-sub-heading'>With advance ML & AI technques</p></center>
                    {features.map((ai_feature, index) => {
                        const isEven = index % 2 === 0;

                        // Determine AOS animation based on index
                        const aosAnimation = isEven ? 'fade-right' : 'fade-left';

                        return (
                            <Col lg={12} md={12} sm={12} className='mb-1 mt-1' key={ai_feature.id}>
                                <Row 
                                    id={ai_feature.id}
                                    className="feature-container d-flex justify-content-center align-items-center"
                                    data-aos={aosAnimation}  // Add AOS animation
                                >   
                                    {/* Image Column for Desktop */}
                                    <Col 
                                        xs={12} sm={12} md={12} lg={12} xl={7} 
                                        className={`mb-2 ${isEven ? 'order-md-1' : 'order-md-2'} d-none d-md-block`}
                                    >
                                        <div className="image-container flex-fill">
                                            <img 
                                                src={ai_feature.gif} 
                                                loading="lazy"
                                                alt={`${ai_feature.title} gif`} 
                                                className="rounded-img"
                                            />
                                        </div>
                                    </Col>

                                    {/* Text Column */}
                                    <Col 
                                        xs={12} sm={12} md={12} lg={12} xl={5} 
                                        className={`mb-2 ${isEven ? 'order-md-2' : 'order-md-1'}`}
                                    >
                                        <div className="flex-fill d-flex flex-column justify-content-center align-items-center">
                                            {/* Icon and Title Container */}
                                            <center>
                                                <div className="mb-2 w-100">
                                                    {/* Icon */}
                                                    <div className="me-2 mb-md-2 pr-2">
                                                        <FontAwesomeIcon 
                                                            icon={ai_feature.icon} 
                                                            className='drone-ai-icons'
                                                        />
                                                    </div>
                                                    
                                                    {/* Title */}
                                                    <p className="drone-ai-feature-title">
                                                        {ai_feature.title}
                                                    </p>
                                                </div>
                                            </center>
                                            
                                            {/* Image for Mobile */}
                                            <div className="my-2 d-block d-md-none w-100">
                                                <div className="image-container flex-fill">
                                                    <img 
                                                        src={ai_feature.gif} 
                                                        alt={`${ai_feature.title} gif`} 
                                                        loading="lazy"
                                                        className="rounded-img"
                                                    />
                                                </div>
                                            </div>
                                            
                                            {/* List */}
                                            <div className="w-100">
                                                <ul className="feature-list text-muted">
                                                    {ai_feature.description.map((point, idx) => (
                                                        <li key={idx}>{point}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })}
                    </div>

                </Row>

                <CinematographyFeatures />
                    
                <YoutubeVideoSection
                    heading = "Drone AI In Action"
                    url="https://youtu.be/_2UGojywVe0"
                />

                <DroneControlOptions />

            </div>
        </div>
    );
}

export default AIFeaturesDisplay;
