import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // <-- Import Link from react-router-dom
import './Footer.css';
import Logo from '../../assets/Logo.png';

const Footer = () => {
  return (
    <div className="footer-container">
      <Container>
        <Row>
          {/* BRAND / LOGO COLUMN */}
          <Col xs={12} md={3} className="footer-section">
            <div className="footer-logo">
              <img 
                className="navbar-logo"
                src={Logo} 
                alt="Drone AI Logo"
              />
              <p>© 2024 AI Drone Software</p>
              <p>support@aidronesoftware.com</p>
            </div>
            
            {/* SOCIAL ICONS: keep <a> for external links */}
            <div className="social-icons">
              {/* Twitter / X */}
              <a 
                href="https://x.com/Tensor_Pilot" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 512 512" 
                  width="24" 
                  height="24"
                  className="svg-icon"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                </svg>
              </a>

              {/* LinkedIn */}
              <a 
                href="https://www.linkedin.com/company/tensor-pilot" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 448 512" 
                  width="24" 
                  height="24"
                  className="svg-icon"
                >
                  <path d="M100.28 448H7.4V149.1h92.88zm-46.44-334.6c-29.6 0-53.6-24.1-53.6-53.6S24.28 6.3 53.84 6.3c29.63 0 53.6 24.13 53.6 53.6S83.44 113.4 53.84 113.4zM447.8 448H354.94V302.4c0-34.7-12.9-58.4-45.2-58.4-24.7 0-39.4 16.6-45.9 32.6-2.4 5.8-3 13.8-3 21.9V448h-92.9s1.2-238.4 0-263h92.9v37.3c12.3-19 34.3-46 83.4-46 60.9 0 106.5 39.7 106.5 125.1V448z"/>
                </svg>
              </a>

              {/* YouTube */}
              <a 
                href="https://www.youtube.com/@tensor-pilot" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 576 512" 
                  width="24" 
                  height="24"
                  className="svg-icon"
                >
                  <path d="M549.655 124.083C534.23 81.062 496.369 49.406 453.946 42.65 410.983 35.76 307.54 32 288 32s-122.983 3.76-165.946 10.65C79.631 49.406 41.769 81.062 26.345 124.083 16.208 154.666 16 228.229 16 228.229s-.208 73.563 10.345 104.146C41.769 430.938 79.631 462.594 122.054 469.35 165.017 476.24 268.46 480 288 480s122.983-3.76 165.946-10.65c42.977-6.889 80.839-38.546 96.263-81.567C559.792 301.792 560 228.229 560 228.229s.208-73.563-10.345-104.146zM232 338.671V138.184L377.276 238.435 232 338.671z"/>
                </svg>
              </a>
            </div>
          </Col>

          {/* HOME COLUMN */}
          <Col xs={6} md={2} className="footer-section">
            <h5>Home</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
                </li>
              <li>
                  <Link to="/signup">Sign Up</Link>
              </li>
            </ul>
          </Col>

          {/* PRODUCTS COLUMN */}
          <Col xs={6} md={2} className="footer-section">
            <h5>Products</h5>
            <ul className="list-unstyled">
              {/* Note: Linking to sections can be tricky. 
                  You may need a special approach (e.g. HashLink) 
                  to scroll to these anchors on the same page. */}
              <li>
                <Link to="/#insight-ai-section">Insight AI</Link>
              </li>
              <li>
                <Link to="/#drone-ai-section">Drone AI</Link>
              </li>
              <li>
                <Link to="/#download-software-section">Download</Link>
              </li>
            </ul>
          </Col>

          {/* RESOURCES COLUMN */}
          <Col xs={6} md={2} className="footer-section">
            <h5>Resources</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/sales">Pricing</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/contactus">Support</Link>
              </li>
            </ul>
          </Col>

          {/* POLICIES COLUMN */}
          <Col xs={6} md={3} className="footer-section">
            <h5>Policies</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {/* FOOTER BOTTOM ROW: LOGIN / SIGNUP BUTTONS */}
        <Row className="footer-bottom text-center">
          <Col xs={12} md={8} lg={8}>
            {/* Extra space or disclaimers here */}
          </Col>
          <Col xs={6} md={2} lg={2}>
            {/* Use as={Link} to avoid page reload */}
            <Button 
              as={Link}
              to="/login"
              variant="outline-primary"
              className="footer-button"
            >
              Login
            </Button>
          </Col>
          <Col xs={6} md={2} lg={2}>
            <Button 
              as={Link}
              to="/signup"
              variant="outline-primary"
              className="footer-button"
            >
              Sign Up
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
