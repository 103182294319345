import React, { useState, useEffect } from 'react';
import './css/FeatureList.css';

const FeaturesList = ({ handleDownloadRef, scrollToDroneAI, scrollToInsightAI }) => {
  const [visibleListItems, setVisibleListItems] = useState(0); // Track visibility of list items

  useEffect(() => {
    // Gradually make each list item visible with a delay
    const timer = setInterval(() => {
      setVisibleListItems((prevVisibleItems) => {
        if (prevVisibleItems < 5) {
          return prevVisibleItems + 1; // Increment the visible item count
        } else {
          clearInterval(timer); // Stop the timer when all items are visible
          return prevVisibleItems;
        }
      });
    }, 300); // Adjust the delay time between items here (300ms)

    return () => clearInterval(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <ul className="list-unstyled">
      <li
        className={`align-items-center my-2 ${
          visibleListItems >= 1 ? 'fade-in delay-1' : 'hidden'
        }`}
      >
        <span className='title-icons'>💻</span>
        <span className="ml-2 text-muted list-text">
          App available on{' '}
          <a
            href="https://apps.microsoft.com/detail/9p7mrszscwnn?cid=DevShareMCLPCS&hl=en-us&gl=CA"
            target="_blank"
            rel="noopener noreferrer"
            className="flow-link"
          >
            Microsoft store
          </a>{' '}
          &{' '}
          <a
            onClick={handleDownloadRef}
            target="_blank"
            rel="noopener noreferrer"
            className="scroll-link"
          >
            MacOS
          </a>
        </span>
      </li>
      <li
        className={`align-items-center my-2 ${
          visibleListItems >= 2 ? 'fade-in delay-2' : 'hidden'
        }`}
      >
        <span className='title-icons'>🚀</span>
        <span className="ml-2 text-muted list-text">
          <a
            onClick={() => scrollToDroneAI()}
            rel="noopener noreferrer"
            className="scroll-link"
          >
            Drone AI
          </a>
          : Advanced drone control software
        </span>
      </li>
      <li
        className={`align-items-center my-2 ${
          visibleListItems >= 3 ? 'fade-in delay-3' : 'hidden'
        }`}
      >
        <span className='title-icons'>🤖</span>
        <span className="ml-2 text-muted list-text">
          <a
            onClick={() => scrollToInsightAI()}
            rel="noopener noreferrer"
            className="scroll-link"
          >
            Insight AI
          </a>
          : Gemini powered personal AI
        </span>
      </li>
      <li
        className={`align-items-center my-2 ${
          visibleListItems >= 3 ? 'fade-in delay-3' : 'hidden'
        }`}
      >
        <span className='title-icons'>👁️</span>
        <span className="ml-2 text-muted list-text">
          Vision AI: Live video analytics
        </span>
      </li>
      
      <li
        className={`align-items-center my-2 ${
          visibleListItems >= 4 ? 'fade-in delay-4' : 'hidden'
        }`}
      >
        <span className='title-icons'>⚙️</span>
        <span className="ml-2 text-muted list-text">
          Tailored drones & robotics software
        </span>
      </li>
    </ul>
  );
};

export default FeaturesList;
