import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo, faBullseye, faClock } from '@fortawesome/free-solid-svg-icons';
import './css/CinematographyFeatures.css';

const CinematographyFeatures = () => {
    const features = [
        {
            title: "Photo Mode",
            description: "Capture high-quality images with your drone's camera.",
            icon: faCamera,
            tooltip: "Photo",
            color: '#FFD700', // Gold
            border: 'warning'
        },
        {
            title: "Video Record Mode",
            description: "Record smooth and stable videos while flying.",
            icon: faVideo,
            tooltip: "Video Record",
            color: '#4CAF50', // Green
            border: 'success'
        },
        {
            title: "Burst Mode",
            description: "Take rapid sequence photos to capture every moment.",
            icon: faBullseye,
            tooltip: "Burst Mode",
            color: '#2196F3', // Blue
            border: 'primary'
        },
        {
            title: "Timer Mode",
            description: "Set a timer for delayed photo shots.",
            icon: faClock,
            tooltip: "Timer",
            color: '#FF5722', // Deep Orange
            border: 'danger'
        }
    ];

    return (
        <div className="cinematography-section">
            <Container>
                <p className="section-subheading text-center text-muted mb-">Cinematography Features</p>
                <Row className="justify-content-center">
                    {features.map((feature, index) => (
                        <Col
                            sm={12}
                            md={6}
                            lg={6}
                            xl={3}
                            xxl={3}
                            key={index}
                            className="mb-2 align-items-stretch"
                        >
                            <Card
                                border={feature.border}
                                className="h-100 text-center shadow-sm fade-in cinematography-card"
                                style={{ animationDelay: `${index * 0.3}s` }}
                            >
                                <Card.Body>
                                    <center>
                                    <FontAwesomeIcon
                                        icon={feature.icon}
                                        className='cinematography-icon'
                                        style={{ color: feature.color }}
                                    />
                                    </center>
                                    <Card.Title className='cinematography-title'>{feature.title}</Card.Title>
                                    <Card.Text className='text-muted'>{feature.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default CinematographyFeatures;
